<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet :title="title">
          <template v-slot:toolbar>
            <b-button variant="dark" :to="{ name: 'handlingExamComplaints' }"
              >{{ $t("GENERAL.COMPLAINTS_HANDLING") }}
              <i class="flaticon2-correct"></i
            ></b-button>
          </template>
          <template v-slot:body>
            <div>
              <b-overlay rounded="sm">
                <b-row>
                  <b-col md="3" lg="3">
                    <b-form-group :label="$t('GENERAL.SELECT_SEMESTER')">
                      <b-form-select
                        v-model="student_class_selected"
                        :options="student_classes"
                        @change="getExamResult"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-table
                    :fields="exam_result_fields"
                    :items="exam_result"
                    :busy="isBusy"
                    striped
                    bordered
                    head-variant="dark"
                    show-empty
                  >
                    <template v-slot:empty="scope">
                      <p class="text-center">
                        {{ $t("EXAM_RESULTS.NO_RESULT") }}
                      </p>
                    </template>
                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
                      </div>
                    </template>
                  </b-table>
                </b-row>
                <b-row>
                  <b-table
                    :fields="exam_result_subjects_fields"
                    :items="exam_result_subjects"
                    :busy="isBusy"
                    striped
                    bordered
                    head-variant="dark"
                    show-empty
                  >
                    <template v-slot:empty="scope">
                      <p class="text-center">
                        {{ $t("EXAM_RESULTS.NO_RESULT") }}
                      </p>
                    </template>
                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
                      </div>
                    </template>
                    <template v-slot:cell(actions)="{ item }">
                      <b-button
                        pill
                        size="sm"
                        variant="danger"
                        v-b-tooltip.hover
                        :title="$t('SEND_ERROR_REPORT.SEND_ERROR')"
                        @click="marksErrorReport(item)"
                      >
                        <i
                          class="flaticon2-warning"
                          style="font-size: 1.3em; margin-left: 0;"
                        ></i>
                      </b-button>
                    </template>
                  </b-table>
                </b-row>
              </b-overlay>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div>
      <b-modal
        no-close-on-backdrop
        ref="marksErrorReportModal"
        centered
        :title="
          $t('SEND_ERROR_REPORT.MARKS_ERROR_TITLE', {
            subject: selected_item.name
          })
        "
        @ok="handleOk"
        @hidden="resetModal"
      >
        <template v-slot:modal-header-close><i></i></template>
        <b-overlay :show="form.submitting" variant="transparent" rounded="sm">
          <p class="my-4">
            <b-row>
              <b-col>
                <div role="group">
                  <label>{{ $t("GENERAL.CHANCE") }}:</label>
                  <b-form-select
                    id="exam_chance"
                    name="exam_chance"
                    :options="exam_chances"
                    v-model="$v.form.chance.$model"
                    :state="validateState('chance')"
                  ></b-form-select>
                  <b-form-invalid-feedback id="input-2-live-feedback">
                    {{
                      $t("VALIDATION.REQUIRED", {
                        name: $t("GENERAL.CHANCE")
                      })
                    }}
                  </b-form-invalid-feedback>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div role="group">
                  <label
                    >{{ $t("SEND_ERROR_REPORT.ERROR_DESCRIPTION") }}:</label
                  >
                  <b-form-textarea
                    id="description"
                    name="description"
                    type="text"
                    rows="4"
                    max-rows="6"
                    v-model="$v.form.description.$model"
                    :state="validateState('description')"
                  ></b-form-textarea>
                  <b-form-invalid-feedback id="input-2-live-feedback">
                    {{
                      $t("VALIDATION.REQUIRED", {
                        name: $t("SEND_ERROR_REPORT.ERROR_DESCRIPTION")
                      })
                    }}
                  </b-form-invalid-feedback>
                </div>
              </b-col>
            </b-row>
          </p>
        </b-overlay>
        <template v-slot:modal-ok>{{
          $t("SEND_ERROR_REPORT.SEND_ERROR")
        }}</template>
        <template v-slot:modal-cancel>{{ $t("GENERAL.CANCEL") }}</template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ApiService from "@/common/api.service";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {
      title: this.$t("MENU.EXAM_RESULTS"),
      isBusy: true,
      exam_result_fields: [
        { semester: this.$t("GENERAL.SEMESTER") },
        { class: this.$t("GENERAL.CLASS") },
        { class_time: this.$t("GENERAL.CLASS_TIME") },
        { total_mark: this.$t("EXAM_RESULTS.TOTAL") },
        { average_mark: this.$t("EXAM_RESULTS.AVERAGE") },
        { result: this.$t("EXAM_RESULTS.RESULT") },
        { grade: this.$t("EXAM_RESULTS.GRADE") }
      ],
      exam_result: [],
      exam_result_subjects_fields: [
        { id: this.$t("GENERAL.NUMBER"), visible: false },
        { number: this.$t("GENERAL.NUMBER") },
        { subject_local_name: this.$t("GENERAL.SUBJECT") },
        { final_mark: this.$t("EXAM_RESULTS.FIRST_CHANCE") },
        { second_chance_mark: this.$t("EXAM_RESULTS.SECOND_CHANCE") },
        { third_chance_mark: this.$t("EXAM_RESULTS.THIRD_CHANCE") },
        { actions: this.$t("GENERAL.ACTIONS") }
      ],
      exam_result_subjects: [],
      student_classes: [],
      student_class_selected: 0,
      exam_chances: [
        { value: 1, text: this.$t("EXAM_RESULTS.FIRST_CHANCE") },
        { value: 2, text: this.$t("EXAM_RESULTS.SECOND_CHANCE") },
        { value: 3, text: this.$t("EXAM_RESULTS.THIRD_CHANCE") }
      ],
      selected_item: {
        id: null,
        name: null
      },
      form: {
        chance: null,
        description: null,
        submitting: false
      }
    };
  },
  components: {
    KTPortlet
  },
  validations: {
    form: {
      chance: {
        required
      },
      description: {
        required
      }
    }
  },
  methods: {
    getExamResult() {
      this.isBusy = true;
      let filters = {
        student_class_id: this.student_class_selected
      };
      ApiService.post("student/exam-results", filters).then(({ data }) => {
        this.exam_result = data.exam_result;
        this.exam_result_subjects = data.exam_result_subjects;
        this.isBusy = false;
      });
    },
    marksErrorReport(record) {
      this.selected_item.id = record.id;
      this.selected_item.name = record.subject_local_name;
      this.$refs["marksErrorReportModal"].show();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetModal() {
      this.form.chance = null;
      this.form.description = null;
      this.$v.$reset();
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let formData = {
        id: this.selected_item.id,
        chance: this.$v.form.chance.$model,
        description: this.$v.form.description.$model
      };
      this.form.submitting = true;
      ApiService.put("student/marks-error-report", formData)
        .then(() => {
          this.form.submitting = false;
          this.$nextTick(() => {
            this.$refs["marksErrorReportModal"].hide();
          });
        })
        .catch(() => {
          this.form.submitting = false;
        });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.EXAM_RESULTS"),
        route: this.$route
      },
      { title: "" }
    ]);
    ApiService.get("student/enrolled-classes/exam-results").then(({ data }) => {
      this.student_classes = data.student_classes;
      if (
        this.$route.params.class_id !== null &&
        this.$route.params.class_id !== undefined
      ) {
        this.student_class_selected = this.$route.params.class_id;
      } else {
        this.student_class_selected =
          data.student_classes[0] !== undefined
            ? this.student_classes[0].value
            : 0;
      }
      this.getExamResult();
    });
  }
};
</script>
